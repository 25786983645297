<template>
  <div class="pl-5 pr-5">
    <h3>Importação do mapa cirurgico com arquivo .CSV</h3>
    <b-form-file
      v-model="csvFile"
      :state="csvFile === null ? null : Boolean(rows.length)"
      placeholder="Escolha ou arraste um arquivo aqui"
      drop-placeholder="Drop file here..."
      browse-text="Procurar arquivo..."
    />
    <Button
      class="mt-4"
      :disabled="!rows.length"
      text="Enviar"
      @click="saveMapa"
    />

    <div class="instrucoes">
      <h4>
        <b>
          Instruções para importação correta do mapa cirúrgico
        </b>
      </h4>
      <ul class="item">
        <li>
          Certifique-se de que a exportação do mapa cirúrgico acontece no formato .csv.
        </li>
        <li>
          Certifique-se de que o mapa cirúrgico é o
          que tem o padrão no qual aparece o nome do paciente apenas com as iniciais.
        </li>
        <li>
          Após exportar o arquivo do sistema MV
          , <b>não o abra para visualização. Apenas importe no sistema Invision.</b>
        </li>
        <li>
          Caso tenha tido algum erro na importação do arquivo
          , <b>não tente o importar novamente. Exclua-o, siga as instruções acima para exportação e tente novamente.</b>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import genericRequest from '@/services/genericRequest';
import Button from '@/components/Utils/Button';
import swal from 'sweetalert';

export default {
  name: 'ImportarMapa',
  components: {
    Button,
  },
  data() {
    return {
      csvFile: null,
      rows: [],
    };
  },
  watch: {
    csvFile: {
      deep: true,
      handler(file) {
        if (!file) return;
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = async (event) => {
          try {
            const data = event.target.result;
            // separar header e content de algum lixo no início do arquivo
            const regexHeader = /\s(DT_AGE_CIR.+OBS_OPME)\s+(.+)/s;
            // const [, headerStr, content] = data.match(regexHeader);
            const init = data.match(regexHeader);
            if (!init) {
              throw Error('Não foi possível separar conteúdo e header');
            }
            const headerStr = init[1];
            let content = init[2];
            if (!headerStr) {
              throw Error('Erro no header');
            }
            if (!content) {
              throw Error('Erro no conteúdo');
            }

            // identificar quebra de linhas e quebra de colunas
            content = content.replace(/"(.*?)"|(\n)|(,)/sg, (...m) => {
              if (m[2]) return '<%NEW_ROW%>';
              if (m[3]) return '<%NEW_COLUMN%>';
              return m[1];
            });
            // formar objeto para salvar no banco de dados
            const rows = content.split('<%NEW_ROW%>').reduce((acc, row) => {
              const columns = row.split('<%NEW_COLUMN%>');
              const aviso = columns[1];
              if (!aviso) {
                return acc;
              }
              const foundRow = acc.find((el) => el.aviso === aviso);
              if (foundRow) {
                if (!foundRow.especialidade.includes(columns[5])) {
                  foundRow.especialidade += `, ${columns[5]}`;
                }
                return acc;
              }

              const [dataPtCir, horario] = columns[0].split(' ');
              let [horas, minutos] = horario.split(':');
              if (horas.length === 1) {
                horas = `0${horas}`;
              }
              if (minutos.length === 1) {
                minutos = `0${minutos}`;
              }

              const dataPtArr = dataPtCir.split('/');
              let [dia, mes] = dataPtArr;
              const ano = dataPtArr[2];
              if (dia.length === 1) {
                dia = `0${dia}`;
              }
              if (mes.length === 1) {
                mes = `0${mes}`;
              }
              return [...acc, {
                data_cirurgia: `${ano}-${mes}-${dia}`,
                horario_cirurgia: `${horas}:${minutos}`,
                aviso,
                paciente_iniciais: columns[2],
                paciente_nascimento: columns[3],
                procedimento: columns[4],
                especialidade: columns[5],
                unidade: columns[6],
              }];
            }, []);

            if (!rows.length) {
              swal({
                icon: 'error',
                title: 'Erro ao importar mapa cirúrgico',
                text: `
                  Aconteceu algum erro durante a importação do arquivo... Leia as instruções e tente novamente.
                  Caso persista o erro, contate o Sistema Invision enviando o respectivo arquivo.
                `,
              });
            }

            this.rows = rows;

            // fazer requisição para salvar no banco de dados.
            // no backend, para cada aviso, verificar se já existe,
            // caso positivo, apenas verificar se deve adicionar especialidade ou manter como está, não duplicar
          } catch (e) {
            console.log('error onLoad file csv:', e);
            swal({
              icon: 'error',
              title: 'Erro ao carregar dados',
              text: `
                Favor enviar arquivo .CSV e a mensagem de erro abaixo para suporte InVision: \n
                ${e.message}
              `,
            });
          }
        };
      },
    },
  },
  methods: {
    async saveMapa() {
      try {
        await genericRequest.create({ rows: this.rows }, 'mapaCirurgico');
        this.rows = [];
        this.csvFile = null;
        swal({
          icon: 'success',
          title: 'Bom trabalho!',
          text: 'Dados do mapa cirurgico foram salvos com sucesso',
        });
      } catch (e) {
        console.log('error upload dados csv:', e);
        swal({
          icon: 'error',
          title: 'Erro ao fazer upload dos dados',
          text: `
            Favor enviar arquivo .CSV e a mensagem de erro abaixo para suporte InVision: \n
            ${e.message}
          `,
        });
      }
    },
  },
};
</script>

<style>
  .instrucoes {
    margin-top: 3em;
  }

  h4 {
    margin-left: 25%;
  }

  .item {
    margin-left: 5%;
    margin-top: 1em;
  }
</style>
